<template>
	<header class="bg-gradient-to-r from-[#111826] from-80% to-[#bf0808] text-white relative z-50" id="site-header">
		<div
			class="flex lg:space-x-8 justify-between items-stretch px-2 lg:px-4 py-3 lg:py-4 drop-shadow-md font-poppins"
		>
			<div class="flex mr-4">
				<button
					class="justify-start px-2 mr-2 lg:hidden focus:outline-none focus:shadow-outline"
					ref="navToggleRef"
					@click="toggleMobileNav()"
				>
					<svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
						<path
							v-if="!isMobileMenuOpen"
							fill-rule="evenodd"
							d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
							clip-rule="evenodd"
						></path>
						<path
							v-else
							fill-rule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clip-rule="evenodd"
						></path>
					</svg>
				</button>

				<router-link
					@click="toggleMobileNav(false)"
					:to="{ name: 'home' }"
					title="Blokpax Market"
					class="lg:ml-0 overflow-hidden flex items-center justify-center flex-shrink-0"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 509.82 115.58"
						class="h-64 max-h-8 sm:max-h-12 max-w-[18rem] lg:block [&>.logo]:fill-[#495667] [&>.word]:fill-white"
					>
						<path
							class="logo"
							d="M57.79 0a57.79 57.79 0 1 0 57.79 57.79A57.79 57.79 0 0 0 57.79 0ZM29.68 79.41a2.35 2.35 0 0 1 .49 1.42v8.45a.79.79 0 0 1-1.31.59 43.19 43.19 0 0 1 15.71-73.22.8.8 0 0 1 1 .76v23.12a.77.77 0 0 0 1.2.66 19.9 19.9 0 0 1 26.9 4.61.79.79 0 0 1-.34 1.2l-5.67 2.41a1.19 1.19 0 0 1-1.31-.28 12.18 12.18 0 0 0-20.69 7.46v1.39a1.16 1.16 0 0 1-.72 1.08L39 61.58a.79.79 0 0 1-1.09-.73V30a.79.79 0 0 0-1.25-.64 35.4 35.4 0 0 0-7 50.09ZM57.79 101a43.31 43.31 0 0 1-12.18-1.74 42.2 42.2 0 0 1-6.84-2.67 1.58 1.58 0 0 1-.88-1.42v-24a1.18 1.18 0 0 1 .72-1.09L41.33 69l4.28-1.82 2.31-1a1.21 1.21 0 0 1 1.31.27A12.17 12.17 0 0 0 69.91 59a13 13 0 0 0 0-1.36 1.2 1.2 0 0 1 .72-1.09l5.69-2.41a.78.78 0 0 1 1.09.59 19.88 19.88 0 0 1-30.6 19.65.78.78 0 0 0-1.2.66v15.23a1.18 1.18 0 0 0 .79 1.13 35.5 35.5 0 1 0 7.79-68.91.78.78 0 0 1-.87-.77v-6.18a.79.79 0 0 1 .73-.79 43.84 43.84 0 0 1 9 .15 43.21 43.21 0 0 1-5.26 86.1Z"
						/>
						<path
							class="blokpax-b word"
							d="M183.8 56a16.37 16.37 0 0 0-10.07-28.1v-.07h-26.34a1.57 1.57 0 0 0-1.58 1.57v56.17a1.58 1.58 0 0 0 1.58 1.58h26.34A17.19 17.19 0 0 0 183.8 56Zm-30.11-18.71a1.58 1.58 0 0 1 1.58-1.58h17.15a8.52 8.52 0 1 1 0 17h-17.15a1.57 1.57 0 0 1-1.58-1.57Zm20 42h-18.42a1.58 1.58 0 0 1-1.58-1.58V62.2a1.58 1.58 0 0 1 1.58-1.58h18.46a9.33 9.33 0 0 1 0 18.65Z"
						/>
						<path
							class="blokpax-l word"
							d="M208.49 27.83h-4.73a1.57 1.57 0 0 0-1.57 1.57v56.17a1.57 1.57 0 0 0 1.57 1.58H235a1.58 1.58 0 0 0 1.58-1.58v-4.73a1.57 1.57 0 0 0-1.58-1.57h-23.36a1.58 1.58 0 0 1-1.58-1.58V29.4a1.57 1.57 0 0 0-1.57-1.57Z"
						/>
						<path
							class="blokpax-o word"
							d="M268.93 35.71a21.78 21.78 0 1 1-21.78 21.78 21.8 21.8 0 0 1 21.78-21.78m0-7.88a29.66 29.66 0 1 0 29.66 29.66 29.66 29.66 0 0 0-29.66-29.66Z"
						/>
						<path
							class="blokpax-k word"
							d="M344.47 27.83a2.36 2.36 0 0 0-1.87.91l-16.73 21.54-6.35 8.18a1.19 1.19 0 0 1-2.12-.73V29.4a1.57 1.57 0 0 0-1.57-1.57h-4.73a1.57 1.57 0 0 0-1.58 1.57v56.17a1.58 1.58 0 0 0 1.58 1.58h4.73a1.57 1.57 0 0 0 1.57-1.58V75.1a3.14 3.14 0 0 1 .67-1.93l6.87-8.85a1.18 1.18 0 0 1 1.87 0l7.84 10.09 9.19 11.82a2.34 2.34 0 0 0 1.86.92h6.41a1.18 1.18 0 0 0 .93-1.91L339.3 67.55l-7.87-10.13a1.19 1.19 0 0 1 0-1.45l20.38-26.24a1.18 1.18 0 0 0-.93-1.9Z"
						/>
						<path
							class="blokpax-p word"
							d="M388 27.83h-22.5a1.57 1.57 0 0 0-1.57 1.57v56.17a1.57 1.57 0 0 0 1.57 1.58h4.73a1.58 1.58 0 0 0 1.58-1.58V67.35a1.57 1.57 0 0 1 1.57-1.58H388a19 19 0 1 0 0-37.94Zm0 30.06h-14.62a1.57 1.57 0 0 1-1.57-1.58v-19a1.57 1.57 0 0 1 1.57-1.58H388a11.09 11.09 0 1 1 0 22.18Z"
						/>
						<path
							class="blokpax-a word"
							d="m457.27 85.57-20.48-57a1.18 1.18 0 0 0-1.11-.78h-7.4a1.18 1.18 0 0 0-1.11.78l-20.49 57a1.19 1.19 0 0 0 1.12 1.58h5a2.37 2.37 0 0 0 2.23-1.57l4.25-11.83a1.58 1.58 0 0 1 1.49-1h22.38a1.55 1.55 0 0 1 1.48 1l4.26 11.83a2.37 2.37 0 0 0 2.22 1.57h5a1.18 1.18 0 0 0 1.16-1.58Zm-34.19-22.32 7.79-21.66a1.18 1.18 0 0 1 2.22 0l7.79 21.66a1.19 1.19 0 0 1-1.11 1.58h-15.58a1.18 1.18 0 0 1-1.11-1.58Z"
						/>
						<path
							class="blokpax-x word"
							d="M500.65 28.87 487.07 49a1.18 1.18 0 0 1-2 0l-13.54-20.13a2.36 2.36 0 0 0-2-1h-6a1.18 1.18 0 0 0-1 1.84l18.33 27.2a1.18 1.18 0 0 1 0 1.32l-18.23 27.08a1.18 1.18 0 0 0 1 1.84h6a2.36 2.36 0 0 0 2-1L485.11 66a1.19 1.19 0 0 1 2 0l13.52 20.08a2.38 2.38 0 0 0 2 1h6a1.18 1.18 0 0 0 1-1.84l-18.34-27.05a1.18 1.18 0 0 1 0-1.32l18.32-27.2a1.18 1.18 0 0 0-1-1.84h-6a2.37 2.37 0 0 0-1.96 1.04Z"
						/>
					</svg>
				</router-link>
			</div>

			<div class="flex flex-nowrap min-[681px]:grow">
				<div
					@click="toggleMobileNav(false)"
					class="order-1 min-[681px]:grow lg:basis-full mr-2 md:mx-4 h-auto flex overflow-hidden min-[681px]:mx-4 rounded-lg"
				>
					<div class="relative items-center grow flex" id="autocomplete">
						<!-- algolia autocomplete will render here -->
					</div>
				</div>

				<nav
					ref="navRef"
					class="bpmp-nav"
					:class="isMobileMenuOpen ? 'visible opacity-100' : 'invisible opacity-0'"
				>
					<ul class="lg:flex lg:items-stretch w-full flex-grow basis-full">
						<li
							v-if="!authStore.authenticated"
							class="group flex justify-center items-center pb-4 border-b-2 border-gray-200 lg:hidden"
						>
							<button class="btn-primary-lg" @click="handleLoginClicked">Login</button>
						</li>
						<li class="group lg:flex lg:justify-center lg:items-center featured-collection-fix relative">
							<div class="font-semibold text-lg whitespace-nowrap">Featured Collections</div>
							<ul class="bpmp-nav__children">
								<li
									v-for="storefront in featuredCollections"
									:key="storefront.name"
									class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-700 lg:items-center"
								>
									<div class="mt-1 flex h-11 w-11 shrink-0">
										<img :src="storefront.pfp_image" :alt="storefront.name" class="rounded-lg" />
									</div>
									<div>
										<router-link
											@click="isMobileMenuOpen = false"
											:to="{ name: 'storefront', params: { slug: storefront.slug } }"
											class="font-semibold"
										>
											{{ storefront.name }}
											<span class="absolute inset-0"></span>
										</router-link>
										<p class="mt-1 text-sm lg:hidden">{{ storefront.details.description }}</p>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
				<auth-block @click="toggleMobileNav(false)" class="order-last shrink-0" />
			</div>
		</div>
	</header>
</template>
<script lang="ts" setup>
import { h, onMounted, ref, Fragment, render, computed } from 'vue'
import '@algolia/autocomplete-theme-classic'
import { useRoute } from 'vue-router'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches/dist/esm'
import algoliasearch from 'algoliasearch/lite'

import AuthBlock from '@/components/AuthBlock.vue'
import { useMarketStore } from '@/stores/MarketStore'

import router from '@/router'
import { useAuthStore } from '@/stores/AuthStore'
import { onClickOutside } from '@vueuse/core'

const navRef = ref(null)
const navToggleRef = ref(null)
onClickOutside(
	navRef,
	function () {
		toggleMobileNav(false)
	},
	{ ignore: [navToggleRef] }
)

const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
	key: 'RECENT_SEARCH',
	limit: 5,
})

const isMobileMenuOpen = ref(false)
const marketStore = useMarketStore()

const ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID
const ALGOLIA_SEARCH_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY)

const authStore = useAuthStore()
const route = useRoute()

async function handleLoginClicked() {
	authStore.redirectToLogin(route.path)
}

function toggleMobileNav(isOpen: boolean = null) {
	if (isOpen === null) {
		isMobileMenuOpen.value = !isMobileMenuOpen.value
	} else {
		isMobileMenuOpen.value = isOpen
	}
}

const featuredCollections = computed(() =>
	marketStore.storeFronts
		.filter((storefront) => storefront.navigation_storefront_sort_order !== null)
		.sort((a, b) => a.navigation_storefront_sort_order - b.navigation_storefront_sort_order)
)

onMounted(() => {
	marketStore.load()

	autocomplete({
		container: '#autocomplete',
		plugins: [recentSearchesPlugin],
		openOnFocus: true,
		placeholder: 'Search cards or collections',
		// @ts-ignore
		getSources() {
			return [
				{
					sourceId: 'collections',
					getItems({ query }) {
						return getAlgoliaResults({
							searchClient,
							queries: [
								{ indexName: 'storefront', query },
								{ indexName: 'asset_index', query },
							],
						})
					},
					onSelect({ item }) {
						const { permalink, token_id, storefront_type } = item
						const splitPermaLink = `${permalink}`.split('/')

						if (token_id) {
							// asset
							const assetId = splitPermaLink.pop()
							const slug = splitPermaLink.pop()
							router.push({ name: 'asset', params: { slug, assetId } })
						}

						if (storefront_type) {
							// collection
							const slug = splitPermaLink.pop()
							router.push({ name: 'storefront', params: { slug } })
						}
					},
					templates: {
						item({ item: rawItem, html }) {
							const item = rawItem as any
							return html`
								<article class="flex gap-x-4 py-2 truncate">
									<img
										class="h-12 w-12 flex-none rounded-full bg-gray-50"
										src="${item?.token_id ? item.image : item.pfp_image}"
										alt="${item.name}"
									/>
									<div class="min-w-0">
										<p class="text-sm truncate font-semibold text-gray-900">${item.name}</p>
										<p class="mt-1 truncate text-xs text-gray-500">
											${item.storefront_name ? item.storefront_name : item.description_md}
										</p>
									</div>
								</article>
							`
						},
					},
					renderer: { createElement: h, Fragment, render },
				},
			]
		},
	})
})
</script>

<style lang="css" scoped>
.bpmp-nav {
	@apply absolute
		bg-gray-900
		left-0
		w-full
		max-h-[calc(100vh-4.5rem)]
		top-[4.5rem]
		p-4
		grow
		overflow-auto
		!m-0
		lg:overflow-visible
		lg:w-auto
		lg:h-auto
		lg:left-auto
		lg:relative
		lg:top-auto
		lg:visible
		lg:opacity-100
		lg:bg-transparent
		lg:text-white
		lg:flex
		lg:p-0
		transition-all
		lg:mx-8
		lg:justify-self-start;
}

.bpmp-nav__children {
	@apply py-4
		rounded-bl-lg
		rounded-br-lg
		lg:p-4
		lg:group-hover:opacity-100
		lg:group-hover:visible
		lg:absolute
		lg:w-[max-content]
		lg:bg-gray-900
		lg:top-16
		lg:opacity-0
		lg:invisible
		lg:left-0
		lg:max-h-[calc(100vh-5rem)]
		lg:overflow-auto
		transition-all
		duration-200;
}
</style>

<style>
/* non-scoped css to override Algolia Autocomplete styles */
.aa-DetachedSearchButton {
	@apply h-12
		border-0
		cursor-pointer
		w-12
		p-0
		flex
		justify-center
		items-center
		rounded-lg
		bg-slate-100
		hover:bg-slate-400
		border-slate-300;
}
.aa-Autocomplete {
	@apply w-full;
}
.aa-Form {
	@apply border-0;
}
.aa-Input {
	@apply h-12 truncate;
}
.aa-Panel {
	@apply min-[768px]:max-[1180px]:!w-1/2
	fixed
	!top-14
	lg:!top-16
	z-50;
}
.aa-DetachedContainer .aa-Panel {
	@apply !top-auto;
}
.aa-DetachedSearchButtonIcon {
	cursor: pointer;
}
.aa-DetachedSearchButtonPlaceholder {
	display: none;
}
.aa-SubmitButton {
	@apply flex justify-center items-center w-full px-3;
}
.aa-SubmitIcon {
	fill: #000;
	cursor: pointer;
}
.aa-PanelLayout {
	@apply max-h-[calc(100vh-4.5rem)];
}

.featured-collection-fix:hover:before {
	/*
		this is a hack to fix the hover state on the featured collections hover state.
		there is a gap between the parent and the child element that causes the hover state to flicker.
		adding a pseudo element to fill the gap so the hover state is consistent.
	*/
	content: '';
	@apply lg:w-full
	lg:h-4
	lg:absolute
	lg:-bottom-4;
}
</style>
