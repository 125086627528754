<template>
	<div v-if="itemsInCart >= 0">
		<button
			class="font-poppins bg-gray-200 enabled:bg-gradient-to-tr enabled:from-sky-600 enabled:to-sky-500 rounded-full group enabled:hover:scale-105 enabled:hover:saturate-150 enabled:active:scale-105 enabled:active:saturate-150 transition-all"
			:disabled="itemsInCart == 0"
			popovertarget="cart"
		>
			<div
				class="m-0.5 py-1 pl-1 pr-4 lg:pr-6 bg-gray-200 group-enabled:bg-white flex gap-2 items-center rounded-full transition-all"
			>
				<div
					class="size-12 lg:size-14 bg-gray-300 group-enabled:bg-gradient-to-tr group-enabled:from-sky-600 group-enabled:to-sky-500 grid place-items-center rounded-full text-gray-400 group-enabled:text-white font-medium transition-all"
				>
					<div class="flex items-center gap-1 text-sm lg:text-base">
						<i class="far fa-cart-shopping" />
						{{ itemsInCart.toLocaleString() }}
					</div>
				</div>

				<div class="font-semibold text-base lg:text-lg text-gray-400 group-enabled:text-gray-900">Cart</div>
			</div>
		</button>

		<div
			popover
			id="cart"
			class="fixed inset-0 p-0 bg-gray-50 max-w-prose backdrop:bg-gradient-to-t backdrop:from-gray-950/70 backdrop:to-gray-900/70 backdrop:backdrop-blur-md rounded-xl overflow-hidden shadow-xl border border-sky-900 transition-all"
		>
			<div class="lg:min-w-[50ch] w-full">
				<table class="w-full">
					<thead>
						<tr class="bg-sky-700 text-gray-50">
							<th class="p-4 text-left">Shopping Cart</th>
							<th class="p-4">Qty.</th>
							<th class="p-4 text-right">Price</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="[id, item] in props.items" class="even:bg-gray-100 odd:bg-gray-50">
							<th scope="row" class="p-4 text-balance text-left">
								{{ item.name }}
							</th>
							<td class="text-center p-4">
								{{ item.quantity.toLocaleString() }}
							</td>
							<td class="text-right p-4">
								${{ currencyFormat((item.quantity * item.price) / 100, true) }}
							</td>
						</tr>

						<tr class="border-t border-slate-900/25">
							<th scope="row" class="p-4 text-left">Total</th>
							<td></td>
							<td class="text-right p-4">${{ currencyFormat(totalInCart / 100, true) }}</td>
						</tr>
					</tbody>
				</table>

				<div class="grid lg:grid-cols-[1fr,2fr] gap-x-8 gap-y-4 mt-12 px-4 pb-4 lg:pb-6">
					<button
						class="order-2 lg:order-1 text-gray-400 underline active:no-underline hover:no-underline underline-offset-2"
						popovertarget="cart"
						popovertargetaction="hide"
					>
						Continue Shopping
					</button>
					<button
						@click="handleCheckout"
						class="order-1 lg:order-2 w-full bg-sky-600 hover:saturate-150 transition-all text-white font-poppins rounded-full py-4"
					>
						Checkout
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, PropType, Ref, ref } from 'vue'

import { OrderItem } from '@/types/Item'
import currencyFormat from '@/util/currencyFormat'

const props = defineProps({
	items: {
		type: Object as PropType<Map<string, OrderItem>>,
	},
})

const emit = defineEmits<{
	(e: 'checkout', items: Array<OrderItem>): void
}>()

const itemsInCart = computed(() => {
	let qty = 0
	for (const [id, item] of props.items) {
		qty += item.quantity
	}
	return qty
})

const totalInCart = computed(() => {
	let total = 0
	for (const [id, item] of props.items) {
		total += item.quantity * item.price
	}
	return total
})

function handleCheckout() {
	emit('checkout', Array.from(props.items.values()))
}
</script>
