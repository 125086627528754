<template>
	<div>
		<div class="max-w-screen-md rounded-xl bg-gray-100 p-8 mx-auto text-center my-12">
			<h1 class="text-4xl font-medium tracking-tighter">You must be logged in to view this page.</h1>
			<p class="text-2xl my-4">Click below to login.</p>
			<p class="py-4">
				<button :disabled="loggingIn" class="btn-primary-lg" @click.prevent="login">
					Login
					<span v-if="loggingIn" class="inline-block ml-2">
						<i class="fa-duotone fa-spinner-third fa-spin"></i>
					</span>
				</button>
			</p>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import sleep from '@/util/sleep'
import { useRoute } from 'vue-router'
import { ref } from 'vue'

const authStore = useAuthStore()
const route = useRoute()

const loggingIn = ref<boolean>(false)

async function login() {
	loggingIn.value = true
	const wait = sleep(0.25)

	try {
		const uri = authStore.getLoginUrl(route.path)
		await wait
		window.top.location = uri
	} catch (e) {
		loggingIn.value = false
	}
}
</script>
