<template>
	<div class="flex">
		<login-button v-if="!authStore.authenticated" />
		<nav-account-menu v-else />
	</div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import { onMounted } from 'vue'

import LoginButton from './LoginButton.vue'
import NavAccountMenu from './NavAccountMenu.vue'
import { useEchoChannelStore } from '@/stores/EchoChannelStore'
import { useUserFavoriteStore } from '@/stores/UserFavoriteStore'

const authStore = useAuthStore()
const echoChannelStore = useEchoChannelStore()
const userFav = useUserFavoriteStore()

onMounted(async () => {
	if (await authStore.login()) {
		echoChannelStore.init(authStore.user.id)
		userFav.state = authStore.user.favorites
	} else {
		echoChannelStore.init()
	}
})
</script>
