<template>
	<div class="@container">
		<div
			class="w-full aspect-[4/5] md:aspect-[1.61] xl:aspect-[2] max-h-[24rem] md:max-h-[48rem] bg-cover bg-center bg-[url(/image/drops/hero-800x.webp)] @md:bg-[url(/image/drops/hero-1200x.webp)] @xl:bg-[url('/image/drops/hero-2000x.webp')] @4xl:bg-[url('/image/drops/hero-2600x.webp')]"
		></div>
	</div>

	<template v-if="drops.length > 0">
		<div
			class="sticky top-16 lg:top-20 h-24 lg:h-26 -mt-24 lg:-mt-26 w-full items-center bg-gray-950/75 backdrop-blur-lg z-40"
		>
			<div class="flex max-w-screen-lg mx-auto items-center px-4 py-4 text-gray-50">
				<div class="text-lg xl:text-2xl font-poppins font-medium flex-grow text-balance">
					Home Team Heroes Drops
				</div>

				<div class="flex-none justify-self-end">
					<HTHCart :items="selected" @checkout="handleCheckout" />
				</div>
			</div>
		</div>

		<div class="max-w-screen-lg mx-auto w-full px-4 mb-24">
			<template v-for="season in seasons">
				<div class="font-poppins font-black text-4xl mt-12 mb-2">{{ season }}</div>

				<div class="space-y-8 overflow-hidden">
					<HTHDrop
						v-for="(drop, i) in seasonDrops(season)"
						:drop="drop"
						:anchor="anchor(drop)"
						:delay="seasonDelay(season, 5000, i)"
						:timer="5000"
						@quantity-changed="handleQuantityChanged"
					/>
				</div>
			</template>
		</div>
	</template>

	<template v-else>
		<div class="my-36">
			<Loader />
		</div>
	</template>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, reactive, watch } from 'vue'
import { isAfter } from 'date-fns'

import api from '@/util/api'
import { default as HTHDrop } from '@/components/HTH/Drop.vue'
import { default as HTHCart } from '@/components/HTH/Cart.vue'
import { Drop } from '@/types/Drop'
import { type OrderItem } from '@/types/Item'
import Loader from '@/components/Loader.vue'
import sleep from '@/util/sleep'
import { omnisend } from '@/util/omnisend'

const SALE = 'hth-warehouse'

const drops = ref<Drop[]>([])
const selected = reactive<Map<string, OrderItem>>(new Map())

onMounted(async () => {
	const res = (await api.loadDrops(SALE)).sort(sortDrops)
	drops.value = res
})

const seasons = computed<number[]>(() => {
	return (
		drops.value
			?.map((drop) => {
				return drop.season
			})
			.filter((drop, i, drops) => {
				return drops.indexOf(drop) === i
			}) ?? []
	)
})

function sortDrops(a: Drop, b: Drop): number {
	if (a.openable_at < b.openable_at) return +1
	if (a.openable_at > b.openable_at) return -1

	return 0
}

const seasonDrops = (season: number) => {
	if (drops.value.length == 0) {
		return []
	}

	return drops.value.filter((drop) => drop.season === season).filter((drop) => drop.id != undefined)
}

const seasonDelay = (season: number, timer: number, i: number) => {
	if (drops.value.length == 0) {
		return 0
	}

	const ret = Math.round(
		(i * timer) / seasonDrops(season).filter((drop) => isAfter(new Date(), new Date(drop.openable_at))).length
	)
	return ret
}

function handleQuantityChanged(oi: OrderItem) {
	selected.set(oi.id, oi)
	omnisend('added product to cart', {
		origin: 'api',
		eventVersion: '',
		eventTime: new Date(),
		properties: {
			addedItem: {
				productID: oi.id,
				productTitle: oi.name,
				productQuantity: oi.quantity,
				productPrice: oi.price,
			},
		},
	})
}

async function handleCheckout(items: Array<OrderItem>) {
	await api
		.createSaleOrder(SALE, items)
		.then((resp) => {
			omnisend('started checkout', {
				origin: 'api',
				eventVersion: '',
				eventTime: new Date(),
				properties: {
					lineItems: items.map((oi) => {
						return {
							productID: oi.id,
							productTitle: oi.name,
							productQuantity: oi.quantity,
							productPrice: oi.price,
						}
					}),
				},
			})
			return resp
		})
		.then((resp) => (top.location.href = resp.redirect))
		.catch((e) => alert("Couldn't process your order right now."))
}

function anchor(drop: Drop): string {
	return `${drop.sport}-${drop.season}${drop.drop ? '-drop-' + drop.drop : ''}`.toLowerCase()
}
</script>
