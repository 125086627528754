<template>
	<div class="@container">
		<div
			class="w-full aspect-[4/5] md:aspect-[1.61] xl:aspect-[2] max-h-[16rem] md:max-h-[32rem] bg-cover bg-center bg-[url(/image/drops/hero-800x.webp)] @md:bg-[url(/image/drops/hero-1200x.webp)] @xl:bg-[url('/image/drops/hero-2000x.webp')] @4xl:bg-[url('/image/drops/hero-2600x.webp')]"
		></div>
	</div>

	<template v-if="drop">
		<div
			class="sticky top-16 lg:top-20 h-24 lg:h-26 -mt-24 lg:-mt-26 w-full items-center bg-gray-950/75 backdrop-blur-lg z-40"
		>
			<div class="flex max-w-screen-lg mx-auto items-center px-4 py-4 text-gray-50">
				<div class="text-lg xl:text-2xl font-poppins font-medium flex-grow text-balance text-ellipsis">
					{{ drop.sport }} {{ drop.season }}{{ drop.drop ? ': Drop ' + drop.drop : '' }}
				</div>

				<div class="flex-none justify-self-end">
					<HTHCart :items="selected" @checkout="handleCheckout" />
				</div>
			</div>
		</div>

		<div class="max-w-screen-lg mx-auto w-full px-4 mb-24">
			<div class="mt-12 overflow-hidden space-y-8">
				<HTHDrop
					:drop="drop"
					:anchor="anchor(drop)"
					:delay="0"
					:timer="5000"
					@quantity-changed="handleQuantityChanged"
				/>

				<div>
					<a
						href="/hometeamheroes"
						class="font-poppins text-2xl inline-flex items-center gap-2 text-gray-600 hover:text-gray-950 active:text-gray-950 transition-colors"
					>
						<div>Show All Drops</div>
						<i class="far fa-chevron-right"></i>
					</a>
				</div>
			</div>
		</div>
	</template>

	<template v-else>
		<div class="my-36"><Loader /></div>
	</template>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue'

import api from '@/util/api'
import { default as HTHDrop } from '@/components/HTH/Drop.vue'
import { default as HTHCart } from '@/components/HTH/Cart.vue'
import { Drop } from '@/types/Drop'
import { type OrderItem } from '@/types/Item'
import sleep from '@/util/sleep'
import Loader from '@/components/Loader.vue'
import { omnisend } from '@/util/omnisend'

const SALE = 'hth-warehouse'

const selected = reactive<Map<string, OrderItem>>(new Map())
const drop = ref<Drop>(undefined)
const props = defineProps({
	drop: {
		type: String,
		required: false,
		default: null,
	},
})

onMounted(async () => {
	drop.value = (await api.loadDrops(SALE)).find((drop) => anchor(drop) === props.drop)
})

function handleQuantityChanged(oi: OrderItem) {
	omnisend('added product to cart', {
		origin: 'api',
		eventVersion: '',
		eventTime: new Date(),
		properties: {
			addedItem: {
				productID: oi.id,
				productTitle: oi.name,
				productQuantity: oi.quantity,
				productPrice: oi.price,
			},
		},
	})
	selected.set(oi.id, oi)
}

async function handleCheckout(items: Array<OrderItem>) {
	await api
		.createSaleOrder(SALE, items)
		.then((resp) => {
			omnisend('started checkout', {
				origin: 'api',
				eventVersion: '',
				eventTime: new Date(),
				properties: {
					lineItems: items.map((oi) => {
						return {
							productID: oi.id,
							productTitle: oi.name,
							productQuantity: oi.quantity,
							productPrice: oi.price,
						}
					}),
				},
			})
			return resp
		})
		.then((resp) => (top.location.href = resp.redirect))
		.catch((e) => alert("Couldn't process your order right now."))
}

function anchor(drop: Drop): string {
	return `${drop.sport}-${drop.season}${drop.drop ? '-drop-' + drop.drop : ''}`.toLowerCase()
}
</script>
